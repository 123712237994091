<template>
   <v-container fluid >
    <v-row class="justify-center">
    <v-card v-if="users">
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"

    >
      <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title>Usuarios </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=""
                  v-on="on"
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  icon
                  x-large
                  @click="dialog = true"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Cadastrar novo usuario
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
    </v-data-table>  
    </v-card>
        <v-dialog
      class="justify-center"
      v-model="dialog"
            v-if="dialog"

      
      max-width="600px"
      transition="dialog-transition"
    >
      <newUser
        v-on:update-users="getUsers()"
        v-on:close-dialog="dialog = false"
      />
    </v-dialog>
    
          <!-- <v-card>
      <v-toolbar color="teal" dark flat dense>
        <v-toolbar-title>Cadastro de Vendedor</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-autocomplete
            label="Nome"
            placeholder="Informe o nome do vendedor"
            v-model="vendedor"
            return-object
            :items="sellers"
            item-text="nome"
            hide-no-data
            disable-lookup
            appen
            icon=""
          >
          </v-autocomplete>
          {{ vendedor }}
          <v-select
            label="Time"
            v-model="select"
            :items="items"
            :rules="[(v) => !!v || 'Informe o time!']"
            required
            placeholder="Informe o time do vendedor"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit" outlined color="primary">Cadastrar</v-btn>
        <v-btn @click="clear" text color="error">Cancelar</v-btn>
      </v-card-actions>
    </v-card> -->
       <!-- <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-toolbar-title>Cadastro de Usuario</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            label="CPF"
            v-model="name"
            required
            placeholder="Informe o CPF"
          ></v-text-field>
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            v-model="password"
            label="Senha"
            hint="Pelo menos 8 caracteres"

            placeholder="A senha é gerada automaticamente"
            @click:append="show2 = !show2"
          ></v-text-field>

          <v-select
            label="Nivel de acesso"
            v-model="select"
            :items="items"
            :rules="[(v) => !!v || 'Informe o time!']"
            required
            placeholder="Informe o time do vendedor"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit" outlined color="primary">Cadastrar</v-btn>
        <v-btn @click="clear" text color="error">Cancelar</v-btn>
      </v-card-actions>
    </v-card> -->
    </v-row>
  </v-container>
</template>

<script>
import newUser from './newUser'

export default {
    name: "Users",
    components: {
      newUser
    },
data: () =>{
    return{
      dialog: false,
      users: null,
          headers: [
        { text: "ID", value: "id" },
                { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "Permissão", value: "tipo" },
      ],

    seller: {
      nome: "",
      email: "",
      telefone: "",
      cpf: "",
      senha: "",
      old_senha: "",
      fk_role: "",
    },

    }
},
created() {
  this.getUsers()
  
},
methods: {
  async getUsers() {
    try {
      const response = await this.$http.get('users')
      this.users = response.data
    } catch (error) {
        this.$toast.error("Erro ao listar usuarios!")
    }
  },

}
}
</script>

<style>

</style>