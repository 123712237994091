<template>
  <v-card v-if="sellers">
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Cadastro de usuario</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>

    <v-card-text class="mt-10">
      <v-form v-model="valid" ref="form">
           <v-autocomplete
        v-model="seller"
                  :items="sellers"
 
          label="Funcionário"
          item-text="nome"
          outlined
        return-object
                  @change="passwordGenerate()"

        >
        </v-autocomplete>
        <!-- <v-select
          :items="sellers"
 
          label="Funcionário"
          item-text="nome"
          v-model="seller"
          return-object
          @change="passwordGenerate()"
          outlined
        ></v-select> -->
        <v-select
          :items="permissions"
          label="Permissão"
          item-text="name"
          item-value="id"
          v-model="roleSelected"
          outlined
        ></v-select>
        <v-alert v-if="seller" color="primary" outlined :value="true">
          Senha: <strong> {{ password }} </strong>
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="create()"> Cadastrar usuario </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      e1: 1,
      sellers: null,
      searchInput: null,
      password: null,
      valid: false,
      roleSelected: null,
      idteam: null,
      seller: null,
      user: null,
      permissions: [
        { id: 1, name: "Vendedor" },
        { id: 2, name: "Coordenador" },
        { id: 3, name: "Administrador" },
        { id: 4, name: "Recurso Humanos" },

      ],
    };
  },
  created() {
    this.getSellers();
    this.passwordGenerate();
  },
  methods: {
    async create() {
      try {
        this.user = {
            user: {
          nome: this.seller.nome,
          cpf: this.seller.cpf,
          senha: this.password,
          status: true,
          role: this.roleSelected,
          fk_vendedor: this.seller.id
          }
        };
        await this.$http.post("user", this.user);
        this.$toast.success("Usuario criado com sucesso");
        this.$emit("update-users");
      } catch (error) {
        this.$toast.error("Usuario já cadastrado!");
      }
    },
    passwordGenerate() {
      let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyz123456789",
        passwd = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        passwd += charset.charAt(Math.floor(Math.random() * n));
      }

      this.password = passwd;
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    async getSellers() {
      await this.$http.post("/saleperson/import")

      const result = await this.$http.get("/saleperson");
      this.sellers = result.data;
    },

    async registerTime() {
      const result = await this.$http.post("/team", this.team);
      this.idteam = result.data.id;
      //this.bulk.team = result.data.id;
      this.$emit("update-team");
    },
  },
};
</script>

<style>
</style>